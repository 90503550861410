<template>
  <!-- <div class="home"> -->
  <!-- <main role="main"> -->
  <!-- <section class="mt-4 mb-5"> -->
  <div class="container-fluid">
    <div class="row">
      <div class="card-columns">
        <!-- <article class="card"> -->
        <a target="_blank" rel="noopener noreferrer" href="https://bisbeeplusclone.web.app/">
          <article class="card">
            <img class="card-img" src="/docs/assets/img/disneyplusmemphis.png" alt="Card image" />

            <div class="card-body">
              <ul>
                <li>
                  A frontend single-page clone web application built with React, Styled Components, Redux, Google login
                  authentication, and deployed with Firebase
                </li>
              </ul>
            </div>
          </article>
        </a>
        <a target="_blank" rel="noopener noreferrer" href="https://plantfab.netlify.app/">
          <article class="card">
            <img class="card-img" src="/docs/assets/img/plantfabmemphis.png" alt="Card image" />
            <!-- <div class="overlay">
                    <h2 class="card-title title">PlantFab</h2>
                  </div> -->
            <div class="card-body">
              <ul>
                <li>
                  A full-stack web application integrated with Twilio API via Ruby gem that helps you to remind yourself
                  to give your plants care.
                </li>
                <li>Frontend multi-page application built with npm, webpack, Vue.js, and Bootstrap</li>
                <li>Backend RESTful API using MVC architecture built with Ruby on Rails</li>
              </ul>
            </div>
          </article>
        </a>
        <a target="_blank" rel="noopener noreferrer" href="https://fellowtube.netlify.app">
          <article class="card">
            <img class="card-img" src="/docs/assets/img/fellowtubememphis.png" alt="Card image" />
            <div class="card-body">
              <ul>
                <li>
                  Frontend single-page responsive web application that emulates a popular video sharing service built
                  with React, Material UI 5, RapidAPI, and deployed with Netlify
                </li>
              </ul>
            </div>
          </article>
        </a>
      </div>
    </div>
  </div>
  <!-- </section> -->
  <!-- </main> -->
  <!-- </div> -->
  <footer class="footer pt-5 pb-5 text-center"></footer>
</template>

<style scoped>
.card-img {
  border-radius: 0;
  cursor: pointer;
}
</style>

<script>
export default {
  data: function () {
    return {};
  },
  created: function () {},
  methods: {},
};
</script>
